/*@tailwind base;*/

/**
 * Custom base styles
 */

* {
    /* Text rendering */
    text-rendering: optimizeLegibility;
    -o-text-rendering: optimizeLegibility;
    -ms-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    -webkit-text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent;
}

* :focus {
    outline: none !important;
}

html {
    font-size: 62.5%;
    font-family: 'Inter var', Roboto, Helvetica Neue, Arial, sans-serif;
    background-color: #121212;
}

body {
    font-size: 14px;
    line-height: 1.4;
    overflow-x: hidden;
    font-feature-settings: "salt";
}

html,
body {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

html,
body {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0;
    min-height: 100%;
    width: 100%;
    flex: 1 1 auto;
}

#root {
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
}

h1,
.h1 {
    font-size: 24px;
}

h2,
.h2 {
    font-size: 20px;
}

h3,
.h3 {
    font-size: 16px;
}

h4,
.h4 {
    font-size: 15px;
}

h5,
.h5 {
    font-size: 13px;
}

h6,
.h6 {
    font-size: 12px;
}

.ps>.ps__rail-y,
.ps>.ps__rail-x {
    z-index: 99;
}

a[role=button] {
    text-decoration: none;
}

[role="tooltip"] {
    z-index: 9999;
}

.MuiModal-root {
    z-index: 999;
}

.MuiAutocomplete-popper {
    z-index: 99999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: rgba(0, 0, 0, 0);
    }

    ::-webkit-scrollbar:hover {
        width: 8px;
        height: 8px;
        background-color: rgba(0, 0, 0, 0.06);
    }

    ::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        border-radius: 20px;
    }

    ::-webkit-scrollbar-thumb:active {
        border-radius: 20px;
    }
}

form label {
    z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
    transition: none !important;
    animation: none !important;
}

button:focus {
    outline: none;
}

/* Removes webkit's autofill backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transitionDelay: 9999s;
    transitionProperty: background-color, color;
}

:focus {
    outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
    height: 1px !important;
}

.leaflet-tooltip.tooltip-trasparent {
    background-color: transparent;
    border: transparent;
    box-shadow: none;
    font-weight: bold;
    font-size: 30px;
}

.leaflet-container {
    min-width: 100%;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
}

.markerLabel {
    color: #ff790d;
    font-size: medium;
    text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
        1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
    font-weight: 800;
}

.popUpButton {
    align-items: center;
    background-color: #0A66C2;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-size: 11.5px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    margin-right: 5px;
    margin-top: 10px;

}

.popUpButton:hover,
.popUpButton:focus {
    background-color: #16437E;
    color: #ffffff;
}

.popUpButton:active {
    background: #09223b;
    color: rgb(255, 255, 255, .7);
}

.popUpButton:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, .08);
    color: rgba(0, 0, 0, .3);
}


.popUpAlert {
    align-items: center;
    background-color: #f00;
    border: 0;
    border-radius: 100px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-flex;
    font-size: 11.5px;
    font-weight: 600;
    justify-content: center;
    line-height: 20px;
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    touch-action: manipulation;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
}

.noRounded .MuiOutlinedInput-root {
    border-radius: 0;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.opta-font-color {
    color: #3730a3;
}

.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid #3730a3;
    border-right: 2px solid transparent;
    animation: spinner .6s linear infinite;
}

/* color picker input */

.colorSelector[type="color"] {
    /* -webkit-appearance: none; */
    border: none;
}

.colorSelector[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

.colorSelector[type="color"]::-webkit-color-swatch {
    border: solid #000 2.5px;
    /* border-radius: 1.2rem; */
    cursor: pointer;
}

.w-fullVW {
    width: 100vw;
}

.h-fullVH {
    height: 100vh;
}

.reset {
    font-size: 15px;
}

div:has(.w-full-parent) {
    width: 100% !important;
}

.results.active>div {
    font-size: 15px;

}

.leaflet-container-clients>.leaflet-control-container>.leaflet-geosearch-bar {
    margin: 1rem 10px 0 !important;
    width: 33.333333%;
}

.leaflet-container-clients-border {
    border-radius: 5px !important;
}

.filter-purple {
    filter: invert(13%) sepia(100%) saturate(6996%) hue-rotate(263deg) brightness(87%) contrast(118%);
}

.filter-purpleBlue {
    filter: invert(26%) sepia(78%) saturate(4041%) hue-rotate(225deg) brightness(96%) contrast(94%);
}

.filter-grey {
    filter: invert(44%) sepia(0%) saturate(1%) hue-rotate(56deg) brightness(90%) contrast(86%);
}

.filter-red {
    filter: invert(24%) sepia(79%) saturate(7491%) hue-rotate(356deg) brightness(98%) contrast(117%);
}

.preloader {
    position: relative;
}

.preloader span {
    display: block;
    bottom: 0px;
    width: 9px;
    height: 5px;
    background: #3730a3;
    position: absolute;
    animation: preloader 1.5s infinite ease-in-out;
}

.preloader span:nth-child(2) {
    left: 11px;
    animation-delay: .2s;
}

.preloader span:nth-child(3) {
    left: 22px;
    animation-delay: .4s;
}

.preloader span:nth-child(4) {
    left: 33px;
    animation-delay: .6s;
}

.preloader span:nth-child(5) {
    left: 44px;
    animation-delay: .8s;
}

@keyframes preloader {
    0% {
        height: 5px;
        transform: translateY(0px);
        background: #9b59b6;
    }

    25% {
        height: 30px;
        transform: translateY(15px);
        background: #111827;
    }

    50% {
        height: 5px;
        transform: translateY(0px);
        background: #9b59b6;
    }

    100% {
        height: 5px;
        transform: translateY(0px);
        background: #9b59b6;
    }
}

.leaflet-draw-actions>li>a {
    background-color: #540AF0;
    color: #fff !important;
    font-weight: 900 !important;
    text-decoration: none !important;
}

.leaflet-draw-actions :hover {
    background-color: #818cf8 !important;
}

.slide-exit {
    transform: translate(100%, 0%);
    transition: transform 500ms ease-in-out;
    animation-delay: 0s;
}

.slide-exit-active {
    transform: translate(0%, 0%);
    transition: transform 500ms ease-in-out;
    animation-delay: 3s;
}


.progress-bar {
    box-sizing: content-box;
    height: 20px;
    position: relative;
    background: #555;
    border-radius: 25px;
}

.progress-bar>span {
    display: block;
    height: 100%;
    background-color: #7500ef;
    background-image: linear-gradient(center bottom,
            #7500ef 37%,
            #c2a1e6 69%);
    position: relative;
    overflow: hidden;
}

.progress-bar>span:after,
.animate>span>span {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(-45deg,
            rgba(255, 255, 255, 0.2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, 0.2) 50%,
            rgba(255, 255, 255, 0.2) 75%,
            transparent 75%,
            transparent);
    z-index: 1;
    background-size: 50px 50px;
    animation: move 2s linear infinite;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}

.animate>span:after {
    display: none;
}

@keyframes move {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 50px 50px;
    }
}

.side-bar-float-buttons {
    position: sticky;
    float: right;
    right: 1.0rem;
    bottom: 2.4rem;
    z-index: 99;
    display: flex;
    gap: 1.0rem;
}

@keyframes fade {
    from { opacity: 0.5; }
}

.blinking {
    animation: fade 0.5s infinite alternate;
}

.geofence-item .geofence-buttons {
    display: none;
}

.geofence-item:hover .geofence-buttons {
    display: block;
}
